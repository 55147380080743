import { Icon } from '/features/buildingBlocks/Icon'

import styles from './VideoPlayButton.css'

import playIcon from '/images/icons/play.raw.svg'

export function VideoPlayButton({ onClick, playing, layoutClassName = undefined }) {
  return (
    <button aria-label='video play button' className={cx(styles.component, layoutClassName, playing && styles.playing)} {...{ onClick }}>
      <span className={styles.playButtonCircle}>
        <span className={styles.iconContainer}>
          <Icon icon={playIcon} layoutClassName={styles.iconLayout} />
        </span>
      </span>
    </button>
  )
}
