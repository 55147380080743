import ReactPlayer from 'react-player'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import { ImageCover } from '/features/buildingBlocks/Image'
import { VideoPlayButton } from '/features/buildingBlocks/VideoPlayButton'

import styles from './Video.css'

const playerConfig = {
  vimeo: {
    playerOptions: {
      controls: true,
      transparent: false,
      playsinline: true,
      title: false,
    },
  },
}

export function Video({ url, title = undefined, poster = undefined }) {
  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)

  const [playing, setPlaying] = React.useState(false)
  const [hasInteracted, setHasInteracted] = React.useState(false)

  const { ref: isInViewportRef, isInViewport } = useIsInViewport()

  React.useEffect(
    () => {
      if (playing && !isInViewport) {
        setPlaying(false)
        handleVideoTracking('scrolled out of viewport', 'scroll')
      }
    },
    [playing, isInViewport]
  )

  return (
    <div data-x='video' ref={isInViewportRef} className={styles.component}>
      {!hasInteracted && (
        <>
          <VideoPlayButton onClick={handlePlay} layoutClassName={styles.playButtonLayout} {...{ playing }} />
          {poster && <ImageCover aspectRatio={16 / 9} layoutClassName={styles.imageLayout} image={poster} />}
        </>
      )}
      <div className={styles.playerContainer}>
        {isMounted && <ReactPlayer
          ref={videoRef}
          width="100%"
          height="100%"
          onPlay={handlePlay}
          onPause={handlePause}
          onSeek={() => handleVideoTracking('seek', 'click')}
          onEnded={() => handleVideoTracking('finished', 'ended')}
          config={playerConfig}
          controls
          // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.player}
          {...{ url, playing }}
        />}
      </div>
    </div>
  )

  function handlePlay() {
    if (playing) return
    setPlaying(true)
    setHasInteracted(true)
    handleVideoTracking('played', 'click')
  }

  function handlePause() {
    if (videoRef.current?.getInternalPlayer().seeking) {
      return
    }

    setPlaying(false)
    handleVideoTracking('paused', 'click')
  }

  function handleVideoTracking(action, type) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackInteraction({
      title: 'video',
      action,
      type,
      index: 1,
      extraMetaData: {
        media: {
          title,
          type: 'video',
          duration,
          progress
        },
      }
    })
  }
}
